import React, { useState, useEffect } from "react";
import "./AllCertificates.scss";
import Label from "../../components/Label/Label";
import HeaderLogin from "../../components/HeaderLogin/HeaderLogin";
import Footer from "../../components/Footer/Footer";
const AllCertificates = () => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
 
const certificateData=[{
 user:"Anne T. Kwayted",
 startDate:"12/10/2020",
 endDate:"12/10/2021",
 delivered:"yes" 
},
{
  user:"Dulcie Veeta",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }
 ,
 {
   user:"Santi Argo",
   startDate:"12/10/2020",
   endDate:"12/10/2021",
   delivered:"yes" 
  }
  ,
{
  user:"Carmen Sayid",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }
 ,
{
  user:"Ivan Itchinos",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }
 ,
{
  user:"Dulcie Veeta",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }
 ,
{
  user:"Annie Versaree",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }
 ,
{
  user:"Ty R. Leeva",
  startDate:"12/10/2020",
  endDate:"12/10/2021",
  delivered:"yes" 
 }]
  return (
    <div className="allCertificate">
      <div className="allCertificate_inner">
        <HeaderLogin />
        <div className="centerDiv" style={{ height: dimensions.height + "px" }}>
          <div className="certificateContainer">
            <Label className="allTitle" title="All Certificates" />
            <div className="tab">
              <div>SL. NO</div>
              <div>User Name</div>
              <div>Start Date</div>
              <div>End Date</div>
              <div>Delivered</div>
            </div>

            <div className="tableData">
              {certificateData.map((curElem, index) => {
                const { startDate, endDate,user,delivered } = curElem;
                return (
                  <div className="customers" keys={curElem}>
                    <div>{index + 1}.</div>
                    <div>{user}</div>
                    <div>{startDate}</div>
                    <div>{endDate}</div>
                    <div>{delivered}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default AllCertificates;

import React from "react";
import { Link } from "react-router-dom";
import "./AppMap.css";

function AppMap() {
  return (
    <div style={{ cursor: "pointer" }}>
      <p className="headerText">Screens and Components</p>

      <Link className="links" to="/">
        {" "}
        HomePage(Content1)
      </Link>
      <Link className="links" to="/ourservices">
        our services(Content3)
      </Link>
      <Link className="links" to="/iosdevelopment">
        {" "}
        Ios Development(Content4)
      </Link>
      <Link className="links" to="/contactpage">
        {" "}
        Contact Page(Content5)
      </Link>
      <Link className="links" to="/privacy">
        Privacy Policy(Content6)
      </Link>
      <Link className="links" to="/termcondition">
        TermCondition(Content7)
      </Link>
      <Link className="links" to="/packagesSEO">
        {" "}
        PackagesSEO(Content8,9)
      </Link>
      <Link className="links" to="/bloglisting">
        BlogListingPage(Content10)
      </Link>
      <Link className="links" to="/blogpage">
        Blog Page(Content11)
      </Link>
      <Link className="links" to="/verifycertificate">
        {" "}
        Verify Certificate(Content12,13,14,14,15)
      </Link>

      <Link className="links" to="/casestudy">
        Case study(Content17)
      </Link>
      <Link className="links" to="/portfolio">
        Portfolio(Content18)
      </Link>
      <Link className="links" to="/aboutus">
        {" "}
        AboutUs(Content19)
      </Link>
      <Link className="links" to="/portfoliolisting">
        {" "}
        PortfolioListing(Content21)
      </Link>
      <Link className="links" to="/login">
        {" "}
        Login(Content22)
      </Link>
      <Link className="links" to="/detailspage">
        DetailsPage(Content23,24,25,26,27,28,29)
      </Link>
      <Link className="links" to="/leave">
        {" "}
        LeavePage(Content30)
      </Link>
      <Link className="links" to="/career">
        Career(content31)
      </Link>
      <Link className="links" to="/applyleave">
        {" "}
        Apply Leave
      </Link>
    </div>
  );
}

export default AppMap;


import React, { useState,useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from '../../components/Label/Label';
import CustomButton from '../../components/CustomButton/CustomButton';
import './ApplyLeave.scss';
import LeaveCard from "../../components/LeaveCard/LeaveCard";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";

const loginForm = {
  title: {
    name: 'email',
    validate: {
      required: {
        value: true,
        
      },
    },
  },
  FAQ: {
    name: 'email',
    validate: {
      required: {
        value: true,
        
      },
    },
  },
}

const tableData = [
  {
    startdate: '8/30/14',
    enddate: '8/30/14',
    leavetype:'PL',
    reason: 'Amet minim mollit non deserunt ullam',
    status:'Pending',
  },
  {
    startdate: '8/30/14',
    enddate: '8/30/14',
    leavetype:'PL',
    reason: 'Amet minim mollit non deserunt',
    status:'Approved',
  },
  {
    startdate: '8/30/14',
    enddate: '8/30/14',
    leavetype:'PL',
    reason: 'Amet minim mollit non deserunt ullam',
    status:'Approved',
  },
  {
    startdate: '8/30/14',
    enddate: '8/30/14',
    leavetype:'PL',
    reason: 'AAmet minim mollit non deserunt',
    status:'Pending',
  },
];

function ApplyLeave() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [form, setLoginForm] = React.useState();
  const [applyLeave, setApplyLeave] = useState(false)
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const onFormSubmit = () => {}
  const onClickApplyLeave = () => {
    setApplyLeave(!applyLeave);
  };
  return (
    <div className="leave_Page">
      <Header handleClick={handleClick}/>
      <div className='leave_Main'>
        <div className='leave_Type'>
            <LeaveCard
              className='card_Width'
              leaveNos='06'
              leaveType='Applied leave'>
            </LeaveCard>
            <LeaveCard
              className='card_Width'
              leaveNos='16'
              leaveType='Opening'>
            </LeaveCard>
            <LeaveCard
              className='card_Width'
              leaveNos='16'
              leaveType='Generated'>
            </LeaveCard>
            <LeaveCard
              className='card_Width'
              leaveNos='03'
              leaveType='Utilized'>
            </LeaveCard>
            <LeaveCard
              className='card_Width'
              leaveNos='03'
              leaveType='Clossing balance'>
            </LeaveCard>
            <LeaveCard
              className='card_Width'
              leaveNos='02'
              leaveType='Leave without pay'>
            </LeaveCard>
        </div>
        <div className='user_Details'>
            <div className='name_Section'>
              <div className='common_Details'>
                <span className='common'>Name</span>
                <span className='bold'>John doe</span>
              </div>
              <div className='common_Details'>
                <span className='common'>Employee  I’d </span>
                <span className='bold'>102930449</span>
              </div>
            </div>
            <div className='button_Bottom'>
              <CustomButton title='Apply Leave' onClick={onClickApplyLeave}></CustomButton>
            </div>
        </div>
        {
            !applyLeave ?
         
          <div className='leave_Table'>
            <ul className='header'>
                <li className='date'>Start date</li>
                <li className='date'>End date</li>
                <li className='leave'>Leave type</li>
                <li className='reason'>Reason</li>
                <li className='status'>Status</li>
            </ul>
            {
            tableData.map((tableData) => {
            return(
            <ul className='data_List'>
                <li className='date'>
                  {tableData.startdate}
                </li>
                <li className='date'>{tableData.enddate}</li>
                <li className='leave'>{tableData.leavetype}</li>
                <li className='reason'>{tableData.reason}</li>
                <li className='status'>{tableData.status}</li>
            </ul>
            )
            })
            }
          </div>
        :
            <div className='apply_Leave_Section'>
            <Label title='Apply Leave'></Label>
            <div className='date_Section'>
              <div className='date'>
            <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        placeholderText="Select start date"
                      />
                    <i className='icon-mail iconCalendar'></i>
                    </div>
                        <div className='date'>
                       <DatePicker
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        placeholderText="Select end date"
                      />
                  <i className='icon-mail iconCalendar'></i>
                  </div>
            </div>
            <textarea class="form-control heightText" id="exampleFormControlTextarea1" placeholder='Write a reason for leave (Optional)'>
                   </textarea>
                   <div className='attached_Upload'>
                      <form class="form">
                        <div class="file-upload-wrapper" data-text="Upload attachment">
                          <i className='icon-mail addIcon'/>
                          <input name="file-upload-field" type="file" class="file-upload-field" value=""/>
                        </div>
                      </form>
                   </div>
                   <div className='leave_Bottom'>
                    <CustomButton title='Cancel'></CustomButton>
                    <CustomButton title='Apply'></CustomButton>
            </div>
          </div>
          }
       
      </div>
      <Footer/>
    </div>
  );
}

export default ApplyLeave;

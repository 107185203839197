import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Label from "../../components/Label/Label";
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import CardImg from "../../assests/img/port.png";
import CardImg1 from "../../assests/img/port1.png";
import "./PortfolioListing.scss";

const tableData = [
  {
    main: "WebApp",
    src: CardImg,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  },
  {
    main: "WebApp",
    src: CardImg1,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  },
  {
    main: "WebApp",
    src: CardImg,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  },
  {
    main: "WebApp",
    src: CardImg1,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  },
  {
    main: "WebApp",
    src: CardImg,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  },
  {
    main: "WebApp",
    src: CardImg1,
    head: "How Ios is a Blessing for the Businesses of Today?",
    inner: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type",
  }

];
const PortfolioListing = () => {
  let naviagte = useNavigate();
  const [carousalStatus, setCarousalStatus] = useState(true);
  const [addAll, setAddAll] = useState(false);
  const [addIos, setAddIos] = useState(false);
  const [addandroid, setAddandroid] = useState(false);
  const [addwebApp, setAddWebApp] = useState(false);
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  return (
    <div>
      <Header handleClick={handleClick} />
      <div className='portfolio_Listing'>
      <div className='inner_Section'>

        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="tab_Main_Section"
        >
          <Tab eventKey="all" title="All">

            {!addAll && (
              <div className="card_Section flex-wrap">
                {tableData.map((tableData) => {
                  return (
                    <div className="card_Main pb-2">
                      <div className="img_Section">
                        <img src={CardImg} className="blog_Img displayDesktop" alt="..." />
                      </div>
                      <div className="content_Section">
                        <Label className="main_Heading" title={tableData.main}></Label>
                        <Label
                          className="heading"
                          title={tableData.head}
                        ></Label>
                        <div className="inner_TxtSection">
                          <Label
                            className="inner_Txt"
                            title={tableData.inner} ></Label>
                          <span className="read_More">READ MORE</span>
                        </div>
                      </div>
                    </div>


                  )
                })
                }
              </div>
            )}

          </Tab>
          <Tab eventKey="ios" title="Ios">
            {!addIos && (
              <div className="card_Section flex-wrap">
                <div className="card_Main pb-2">
                  <p>Ios</p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="android" title="Android">
            {!addandroid && (
              <div className="card_Section flex-wrap">
                <div className="card_Main pb-2">
                  <p>Android</p>
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="webApp" title="WebApp">
            {!addwebApp && (
              <div className="card_Section flex-wrap">
                <div className="card_Main pb-2">
                  <p>WebApp</p>
                </div>
              </div>
            )}
          </Tab>
        </Tabs>
        </div>
        <Footer/>
      </div>
    </div>
  )
}

export default PortfolioListing

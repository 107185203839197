import React, { useState, useEffect } from "react";
import {
  NavDropdown,
  Nav,
  Dropdown,
  Navbar,
  Accordion,
  Container,
} from "react-bootstrap";
import LogoImg from "../../assests/img/logo1.svg";
import GoogleAds from "../../assests/img/ads.svg";
import FBLogo from "../../assests/img/fb.svg";
import InstaLogo from "../../assests/img/insta.svg";
import LinkedinLogo from "../../assests/img/linkedin.svg";
import TwitterLogo from "../../assests/img/twitter.svg";
import DribbleLogo from "../../assests/img/dribble.svg";
import SkypeLogo from "../../assests/img/skype.svg";
import Label from "../Label/Label";
import { useNavigate } from "react-router-dom";
import chatImg from "../../assests/img/chat.png";
import { useDispatch, useSelector } from "react-redux";

import "./Footer.scss";
import { getFooterDataAction } from "../../reduxThings/slices/DigipankSlices";

const Footer = (props) => {
  const dispatch = useDispatch();

  let navigate = useNavigate();
  useEffect(() => {
    dispatch(getFooterDataAction());
  }, []);

  const appData = useSelector((e) => e.DigipankSlice.footerState.footerData);
  if (typeof appData.companyAddress != "string") {
    return null;
  }
  const clickToNavigate = (page) => {
    console.log("...", page);
    if (page === "Login") {
      navigate("/login");
    } else if (page === "AboutUs") {
      navigate("/aboutus");
    } else if (page === "VerifyCertificate") {
      navigate("/verifycertificate");
    } else if (page === "packages") {
      navigate("/packagesSEO");
    } else if (page === "privacyPolicy") {
      navigate("/privacy");
    } else if (page === "termCondition") {
      navigate("/termcondition");
    }
  };
  return (
    <div className="footer-Container">
      <footer className="footer-Img">
        <div className="footer-Items">
          <ul className="listings-Main">
            <li>
              <a class="brand" href="#" className="logo-Img">
                <img src={LogoImg}></img>
                {/* <img src='../../assests/img/logo1.svg'></img> */}
              </a>
            </li>
            <li className="padd40">
              {appData.companyAddress}
              {/* New Area Gandhi Nagar, Hinoo, <br />
              Ranchi Jharkhand - 834002 */}
            </li>
            {appData.contactUs.map((element) => {})}
            <li>
              <ul className="listings">
                <li className="main-Title">
                  {appData.contactUsHead}
                </li>
                <li>
                  <i className="icon-phone paddR font18"></i>
                  {appData.contactUs[0].value}
                </li>
                <li>
                  <i className="icon-mail paddR font18"></i>
                  {appData.contactUs[1].value}
                </li>
                <li className="padd50">
                  <img src={SkypeLogo} className="paddR "></img>
                  {appData.contactUs[2].value}
                </li>
                <li>
                  <ul className="listings">
                    <li>{appData?.ourAccreditionations[0]?.title}</li>
                    <li>
                      <a class="brand" href="#" className="logo-Img">
                        <img src={GoogleAds}></img>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <ul className="listings-Main">
            <li className="main-Title">{appData.solutionHead}</li>
            <li>{appData.solution[0].title}</li>
            <li>{appData.solution[1].title}</li>
            <li>{appData.solution[2].title}</li>

            <li>{appData.solution[3].title}</li>

            <li>{appData.solution[4].title}</li>
            <li>{appData.solution[5].title}</li>

            <li>{appData.solution[6].title}</li>

            <li>{appData.solution[7].title}</li>

            <li>{appData.solution[8].title}</li>
          </ul>
          <ul className="listings-Main">
            <li className="main-Title">{appData.serviceHead}</li>
            <li>{appData.service[0].title}</li>
            <li>{appData.service[1].title}</li>
            <li>{appData.service[2].title}</li>

            <li>{appData.service[3].title}</li>

            <li>{appData.service[4].title}</li>
            <li>{appData.service[5].title}</li>

            <li>{appData.service[6].title}</li>

            <li>{appData.service[7].title}</li>

            <li>{appData.service[8].title}</li>
            <li>{appData.service[9].title}</li>
          </ul>
          <ul className="listings-Main">
            <li className="main-Title">{appData.FollowHead}</li>
            <li>
              <img src={FBLogo} className="paddR"></img>
              {appData.followuson[0].title}
            </li>
            <li>
              <img src={InstaLogo} className="paddR"></img>
              {appData.followuson[1].title}
            </li>
            <li>
              <img src={TwitterLogo} className="paddR"></img>
              {appData.followuson[2].title}
            </li>
            <li>
              <img src={LinkedinLogo} className="paddR"></img>
              {appData.followuson[3].title}
            </li>
            <li>
              <i className="icon-be colorBlue paddR"></i>
              {appData.followuson[4].title}
            </li>
            <li>
              <img src={DribbleLogo} className="paddR"></img>
              {appData.followuson[5].title}
            </li>
            <li>
              <i className="icon-pinterest colorRed paddR"></i>
              {appData.followuson[6].title}
            </li>
          </ul>
        </div>
        <div className="contact-us-footer position-absolute">
          <a href="" className="d-flex">
            <img src={chatImg} />
            <Label title={(appData.contactUsHead).substring(0,7)}></Label>
          </a>
        </div>
      </footer>
      <div className="copy-Right">
        <div className="lists">
          <div className="copy-Text">
            <Label title={appData.copyright}></Label>
          </div>
          <div className="ul-Footer">
            {/* <li onClick={() => clickToNavigate("Login")}>
              <Label title="Admin"></Label>
            </li> */}
            {/* <li onClick={() => clickToNavigate("AboutUs")}>
              <Label title={appData.bottomRoutes[0].title}></Label>
            </li> */}
           
            <li onClick={() => clickToNavigate("AboutUs")}>
              <Label title={appData.bottomRoutes[1].title}></Label>
            </li>
            
            <li onClick={() => clickToNavigate("VerifyCertificate")}>
              <Label title={appData.bottomRoutes[2].title}></Label>
            </li>
           
            <li onClick={() => clickToNavigate("packages")}>
              <Label title={appData.bottomRoutes[3].title}></Label>
            </li>
           
            <li onClick={() => clickToNavigate("privacyPolicy")}>
              <Label title={appData.bottomRoutes[4].title}></Label>
            </li>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

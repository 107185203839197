import React, { useState,useEffect } from "react";
import Header from "../../components/Header/Header";
import Label from "../../components/Label/Label";
import "./PackagesSEO.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Footer from "../../components/Footer/Footer";
import check from "../../assests/img/check.svg";
// import appData from "../../Data/Packages(Content8).json";
import { useDispatch, useSelector } from "react-redux";
import { getPackagesData } from "../../reduxThings/slices/DigipankSlices";
function PackagesSEO() {
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  const dispatch=useDispatch()
  useEffect(()=>{
dispatch(getPackagesData())
  },[])
  const appData=useSelector((e)=>e?.DigipankSlice?.packagesState?.packagesStateData)
  return typeof appData?.title === 'string'?(
    <div className="package_Seo_Container">
      <Header handleClick={handleClick} />
      <div className="banner_Text">
        <Label title={appData.title} className="packages"></Label>
        <Label
          className="text"
         title= {appData.subTitle}  ></Label>
        <i className="icon-downs"></i>
      </div>
      <div className="tab_container">
        <Tabs
          defaultActiveKey="seo"
          id="uncontrolled-tab-example"
          className="tab_Main_Section"
        >
          <Tab eventKey="seo" title={appData.seo.packagesName}>
            <div className="seo_packageData">
              <div className="silverData">
                <SeoPackage
                  headerText={appData.seo.silver.header}
                  target={appData.seo.silver.silverArray[0].feature}
                  targetText={appData.seo.silver.silverArray[0].Result}
                  best={appData.seo.silver.silverArray[1].feature}
                  bestText={appData.seo.silver.silverArray[1].Result}
                  keyword={appData.seo.silver.silverArray[2].feature}
                  KeyText={appData.seo.silver.silverArray[2].Result}
                  cover={appData.seo.silver.silverArray[3].feature}
                  coverText={appData.seo.silver.silverArray[3].Result}
                  what={appData.seo.silver.silverArray[4].feature}
                  whatText={appData.seo.silver.silverArray[4].Result}
                  improvement={appData.seo.silver.silverArray[5].feature}
                  improvementText={appData.seo.silver.silverArray[5].Result}
                />
              </div>
              <div className="goldData">
              <SeoPackage
                  headerText={appData.seo.gold.header}
                  target={appData.seo.gold.goldArray[0].feature}
                  targetText={appData.seo.gold.goldArray[0].Result}
                  best={appData.seo.gold.goldArray[1].feature}
                  bestText={appData.seo.gold.goldArray[1].Result}
                  keyword={appData.seo.gold.goldArray[2].feature}
                  KeyText={appData.seo.gold.goldArray[2].Result}
                  cover={appData.seo.gold.goldArray[3].feature}
                  coverText={appData.seo.gold.goldArray[3].Result}
                  what={appData.seo.gold.goldArray[4].feature}
                  whatText={appData.seo.gold.goldArray[4].Result}
                  improvement={appData.seo.gold.goldArray[5].feature}
                  improvementText={appData.seo.gold.goldArray[5].Result}
                />
              </div>
              <div className="platinumData">
              <SeoPackage
                  headerText={appData.seo.platinum.header}
                  target={appData.seo.platinum.platinumArray[0].feature}
                  targetText={appData.seo.platinum.platinumArray[0].Result}
                  best={appData.seo.platinum.platinumArray[1].feature}
                  bestText={appData.seo.platinum.platinumArray[1].Result}
                  keyword={appData.seo.platinum.platinumArray[2].feature}
                  KeyText={appData.seo.platinum.platinumArray[2].Result}
                  cover={appData.seo.platinum.platinumArray[3].feature}
                  coverText={appData.seo.platinum.platinumArray[3].Result}
                  what={appData.seo.platinum.platinumArray[4].feature}
                  whatText={appData.seo.platinum.platinumArray[4].Result}
                  improvement={appData.seo.platinum.platinumArray[5].feature}
                  improvementText={appData.seo.platinum.platinumArray[5].Result}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="SMO" title={appData.smo.packagesName}>
            <div className="seo_packageData">
              <div className="silverData">
                <SmoPackage
                  image={check}
                  headerText={appData.smo.silver.header}
                  identityText={appData.smo.silver.silverArray[0].feature}
                  accText={appData.smo.silver.silverArray[1].feature}
                  analyseText={appData.smo.silver.silverArray[2].feature}
                  createText={appData.smo.silver.silverArray[3].feature}
                  contentText={appData.smo.silver.silverArray[4].feature}
                  hastagText={appData.smo.silver.silverArray[5].feature}
                  socialText={appData.smo.silver.silverArray[6].feature}
                />
              </div>
              <div className="goldData">
                <SmoPackage
                  image={check}
                  headerText={appData.smo.gold.header}
                  identityText={appData.smo.gold.goldArray[0].feature}
                  accText={appData.smo.gold.goldArray[1].feature}
                  analyseText={appData.smo.gold.goldArray[2].feature}
                  createText={appData.smo.gold.goldArray[3].feature}
                  contentText={appData.smo.gold.goldArray[4].feature}
                  hastagText={appData.smo.gold.goldArray[5].feature}
                  socialText={appData.smo.gold.goldArray[6].feature}
                />
              </div>
              <div className="platinumData">
                <SmoPackage
                  image={check}
                  headerText={appData.smo.platinum.header}
                  identityText={appData.smo.platinum.platinumArray[0].feature}
                  accText={appData.smo.platinum.platinumArray[1].feature}
                  analyseText={appData.smo.platinum.platinumArray[2].feature}
                  createText={appData.smo.platinum.platinumArray[3].feature}
                  contentText={appData.smo.platinum.platinumArray[4].feature}
                  hastagText={appData.smo.platinum.platinumArray[5].feature}
                  socialText={appData.smo.platinum.platinumArray[6].feature}
                />
              </div>
            </div>
          </Tab>
          <Tab eventKey="ESP" title={appData.ecommerceSeo.packagesName}></Tab>
          <Tab eventKey="ORM" title={appData.orm.packagesName}></Tab>
          <Tab eventKey="PPC" title={appData.ppc.packagesName}></Tab>
        </Tabs>
      </div>
      <Footer></Footer>
    </div>
  )
  :<div>...loading</div>
}

export default PackagesSEO;

const SeoPackage = (props) => {
  let {
    headerText,
    target,
    best,
    keyword,
    cover,
    what,
    improvement,
    targetText,
    bestText,
    KeyText,
    coverText,
    whatText,
    improvementText,
  } = props;
  return (
    <div className="seo_Section">
      <div className="seo_Header">
        <Label title={headerText} className="header"></Label>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={target} className="seo_Row_header"></Label>
          <Label title={targetText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={best} className="seo_Row_header"></Label>
          <Label title={bestText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={keyword} className="seo_Row_header"></Label>
          <Label title={KeyText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={cover} className="seo_Row_header"></Label>
          <Label title={coverText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={what} className="seo_Row_header"></Label>
          <Label title={whatText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="seo_Row_data">
          <Label title={improvement} className="seo_Row_header"></Label>
          <Label title={improvementText} className="seo_Row_text"></Label>
        </div>
      </div>
    </div>
  );
};

const SmoPackage = (props) => {
  let {
    image,
    headerText,
    identityText,
    accText,
    analyseText,
    createText,
    contentText,
    hastagText,
    socialText,
  } = props;
  return (
    <div className="seo_Section">
      <div className="seo_Header">
        <Label title={headerText} className="header"></Label>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={identityText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={accText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={analyseText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={createText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={contentText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={hastagText} className="seo_Row_text"></Label>
        </div>
        <div className="seo_border"></div>
      </div>
      <div className="seo_Row">
        <div className="smo_Row_data">
          <div className="checkGreen">
            <img src={image} alt="image" fluid="true" className="check_Img" />
          </div>
          <Label title={socialText} className="seo_Row_text"></Label>
        </div>
      </div>
    </div>
  );
};

const Constant = {
    REGEX: {
        email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        SPECIAL_CHAR: /[!@#\$%\^\&*\)\(+=._-]/g,
        NUMBER: /[0-9]/g,
        Name: /^[ a-zA-Z]+$/i,
        // ALPHANUMERIC: /^"^[a-zA-Z0-9_]*$"/i,
        ALPHANUMERIC: /^[ a-zA-Z0-9\.\-\,]+$/i,
        LOWERCASE_UPPERCASE: /[a-z].*[A-Z]|[A-Z].*[a-z]/,
        Url: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i,
        commission: /[1-9]$|^[1-9][0-9]$|^(100)$/i,
        AMOUNT: /^[$0-9]*$/,
        NUMERIC: /^\d*\.?\d*$/,
    }
}

export default Constant
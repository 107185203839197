import { useState, useEffect } from "react";
import "./CaseStudy.scss";
import Header from "../../components/Header/Header";
import img21 from "../../assests/img/image 21.svg";
import img24 from "../../assests/img/image 24.svg";
import case1 from "../../assests/img/casestudy.svg";
import Footer from "../../components/Footer/Footer";
import { useDispatch, useSelector } from "react-redux";
import { getCaseStudyAction } from "../../reduxThings/slices/DigipankSlices";
const CaseStudy = () => {
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    dispatch(getCaseStudyAction());
  }, []);
  const appData = useSelector(
    (e) => e?.DigipankSlice?.caseStudyState?.caseStudyStateData
  );
  console.log(appData, "caseStudy");
  return typeof appData?.title === "string" ? (
    <div className="CaseStudy_section">
      <Header handleClick={handleClick} />
      <div className="fintech">
        <p>{appData.title}</p>
        <p className="fact">{appData.subTitle}</p>
      </div>
      <Card
        className="firstCard"
        im={img21}
        head={appData?.cardDataOne?.heading}
        p1={appData?.cardDataOne?.listData[0]?.point}
        p2={appData?.cardDataOne?.listData[1]?.point}
        p3={appData?.cardDataOne?.listData[2]?.point}
        p4={appData?.cardDataOne?.listData[3]?.point}
        p5={appData?.cardDataOne?.listData[4]?.point}
      />
      <div className="paraDefine">{appData?.cardDataOne?.aboutDataOne}</div>

      <Cardnew
        flexDirection="row-reverse"
        backgroundColor="white"
        className="secondCard"
        im={img21}
        head={appData?.cardDataTwo?.heading}
        p1={appData?.cardDataTwo?.aboutDataTwo}
      />
      <Card
        className="firstCard"
        im={img24}
        head={appData?.cardDataThree?.heading}
        p1={appData?.cardDataThree?.listData[0]?.point}
        p2={appData?.cardDataThree?.listData[1]?.point}
        p3={appData?.cardDataThree?.listData[2]?.point}
        p4={appData?.cardDataThree?.listData[3]?.point}
        p5={appData?.cardDataThree?.listData[4]?.point}
      />
      <div className="blackBox">
        <div className="case">
          <img src={case1} alt="..." />
        </div>
        <div className="blackBox_text">
          <h2>{appData?.cardDataFour?.heading}</h2>
          <p>{appData?.cardDataFour?.aboutDataFour} </p>
        </div>
      </div>

      <div className="passage">
        <p className="titlePassage">{appData?.afterCardDataFour?.heading}</p>
        <p className="textPassage">{appData?.afterCardDataFour?.para}</p>
      </div>
      <div className="lastTextBox">
        <div className="textBox">
          <p className="titlePassage">{appData?.lastData?.heading}</p>
          <p className="textPassage">{appData?.lastData?.aboutLastData}</p>
        </div>

        <div className="box">
          <h2 className="heading">
            {appData?.lastData?.lastDataCard?.heading}
          </h2>
          <p className="para">
            {appData?.lastData?.lastDataCard?.list[0]?.point}
          </p>
          <p className="para">
            {" "}
            {appData?.lastData?.lastDataCard?.list[1]?.point}
          </p>
          <p className="para">
            {" "}
            {appData?.lastData?.lastDataCard?.list[2]?.point}
          </p>
          <p className="para">
            {appData?.lastData?.lastDataCard?.list[3]?.point}
          </p>
          <p className="para">
            {" "}
            {appData.lastData?.lastDataCard?.list[4]?.point}
          </p>
        </div>
      </div>

      <Footer />
    </div>
  ) : (
    <h1>...loading please wait</h1>
  );
};

export default CaseStudy;
const Card = ({
  im,
  head,
  p1,
  p2,
  p3,
  p4,
  p5,
  className,
  backgroundColor,
  flexDirection,
}) => {
  return (
    <div className={className} style={{ backgroundColor, flexDirection }}>
      <div className="imgSpace">
        <img src={im} alt="..." />
      </div>
      <div className="textSpace">
        <h2 className="title">{head}</h2>
        <p className="para">{p1}</p>
        <p className="para">{p2}</p>
        <p className="para">{p3}</p>
        <p className="para">{p4}</p>
        <p className="para">{p5}</p>
      </div>
    </div>
  );
};
const Cardnew = ({
  im,
  head,
  p1,
  className,
  backgroundColor,
  flexDirection,
}) => {
  return (
    <div className={className} style={{ backgroundColor, flexDirection }}>
      <div className="imgSpace">
        <img src={im} alt="..." />
      </div>
      <div className="textSpace">
        <h2 className="title">{head}</h2>
        <p className="para">{p1}</p>
      </div>
    </div>
  );
};

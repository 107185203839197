import React from 'react';
import { Form } from 'react-bootstrap';
import './TextField.scss';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import InputMask from "react-input-mask";

const TextField = (props) => {
  let { errors, placeholder, multiErrorFields, disabled, type, textarea, name, autoFocus, handleFocus, handleBlur, iconClass,
    onIconClick, onKeyDown, maskType, noTextfield, formMethod, rows, defaultValue, rules , textFieldCss} = props

  let values = formMethod?.getValues()
  let hasError = errors[name] !== undefined;

  return (
    
    <div className={"textField " + textFieldCss}>
      <Form.Group controlId="formBasicEmail">
        <div className="inputBox">
          {noTextfield ?
            <Controller
              className="form-control"
              as={<InputMask />}
              mask={maskType}
              maskChar=""
              control={formMethod?.control}
              name={name}
              rules={rules}
              defaultValue={defaultValue}
              placeholder={placeholder}
              disabled={disabled}
            />
            :
            <Controller
              defaultValue={defaultValue}
              render={({ onChange }) =>
                <Form.Control className='hasErrors'
                  name={name}
                  autoFocus={autoFocus}
                  disabled={disabled}
                  autoComplete="off"
                  type={type}
                  as={textarea}
                  rows={rows}
                  onBlur={() => handleBlur}
                  onKeyDown={onKeyDown}
                  onFocus={(e) => { handleFocus && handleFocus(e) }}
                  onChange={(e) => {
                   
                    e.target.value = e.target.value.trimLeft()
                    onChange && onChange(e.target.value.trimLeft())
                    props.onChange && props.onChange(e)
                  }}
                  placeholder={placeholder}
                  value={formMethod.watch(name)}
                />
              }
              name={name}
              control={formMethod?.control}
              rules={rules}
            />
          }
          <i className={"icon-css " + iconClass} onClick={() => values[name] && values[name] !== '' && onIconClick()}></i>

          {multiErrorFields.length > 0 ?
            <ErrorMessage
              errors={errors}
              name={name}
              render={({ messages }) => {
                if (messages) {
                  let isMultipleError = Object.keys(messages).every((errKey) => multiErrorFields.filter((m) => m[errKey] !== undefined).length > 0)
                  if (isMultipleError) {
                    let arr = []
                    for (const fieldError of multiErrorFields) {
                      let key = Object.keys(fieldError)[0]
                      let success = Object.keys(messages).includes(key)
                      arr.push(
                        <div className={success ? 'red' : 'green'} >
                          <span key={key}>{fieldError[key]}</span>
                        </div>
                      )
                    }
                    return <div className="errorMsg show passwordcustom">{arr}</div>
                  } else {
                    return <div className='errorMsg show'>{errors[name]?.message}</div>
                  }
                } else {
                  return <div className='errorMsg'></div>
                }
              }}
            /> : (hasError ? <div className='errorMsg show'><p>{errors[name]?.message}</p></div> : <div className='errorMsg'>erro</div>)}

        </div>
      </Form.Group>
    </div >
  );
}

TextField.defaultProps = {
  autoFocus: false,
  value: '',
  errors: {},
  multiErrorFields: []
}

export default TextField;

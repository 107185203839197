import React from 'react';
import { Form, NavDropdown, Link, Item } from 'react-bootstrap';
import './SearchBox.scss';

const SearchBox = (props) => {
  let { placeholder, type, value, name, onChange, iconVisible } = props
  return (
    <div className="SearchBox">
      <Form.Group controlId="formBasicEmail">
        <div className="inputBox">
          <Form.Control
            type={type}
            value={value}
            name={name}
            placeholder={placeholder}
            onChange={onChange} />
         <i className="icon-search pointerC"></i>
        </div>
      </Form.Group>
    </div>
  );
}

SearchBox.defaultProps = {
  iconVisible: true
}
export default SearchBox;

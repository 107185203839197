import React from "react";
import './Info.scss'
const Info = (props) => {
  return (
    <div className="info_Details">
      <img
        src={props.icon}
        alt="..."
        
        className="certificate_Img"
        width="50%"
      />
      <div className="name">{props.name}</div>
      <div className="program">{props.program}</div>
    </div>
  );
};

export default Info;

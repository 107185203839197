import React from "react";
import { Card } from "react-bootstrap";
import Label from "../Label/Label";
import './LeaveCard.scss';


const LeaveCard = (props) => {
    let { leaveNos, leaveType } = props;
    
    return (
       <Card className={'leave_Card '  + props.className}> 
           <Label title={leaveNos} className='titleNos'></Label>
           <Label title={leaveType} className='titleType'></Label>
       </Card>
    )
}


export default LeaveCard;
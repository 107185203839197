import React, { useState } from "react";
import './CheckBox.scss';

const CheckBox = (props) => {
    let [checked, setChecked] = useState(props.checked)
    let { labelTitle, id, onCheckedChange } = props

    const onChecked = (e, check) => {
        onCheckedChange ? onCheckedChange(e, check) : setChecked(check)
    }

    return (
        <div className="checkboxMain">
            <input type="checkbox" id={id} className='checkbox' />
            <label className='pointerC' id="checkLabel" htmlFor={id}>{labelTitle}</label>
        </div>
    )
}

CheckBox.defaultProps = {
    id: 1,
    checked: false,
    labelTitle: 'Title',
}

export default CheckBox;
import routes from "./routes";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App" >
      <BrowserRouter>
      <Routes>
        <Route>
          {routes.map((route, index) => {
            return <Route
              key={index}
              exact={route.exact}
              path={route.path}
              element={route.component}
            />
          })}
        </Route>
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, { useState, useEffect } from "react";
import "react-phone-number-input/style.css";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import Label from "../../components/Label/Label";
import aboutImg from "../../assests/img/about.svg";
import checkedImg from "../../assests/img/checked.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import Accordion from "react-bootstrap/Accordion";
import Message from "../../util/message";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import Constant from "../../util/constant";
import "./CareerPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { getCareerPageAction } from "../../reduxThings/slices/DigipankSlices";

const loginForm = {
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  password: {
    name: "password",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.PASSWORDEMPTY,
      },
    },
  },
};
function CareerPage() {
  const dispatch = useDispatch();
  const [carousalStatus, setCarousalStatus] = useState(true);
  const [form, setLoginForm] = React.useState();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const onFormSubmit = (e) => {};
  useEffect(() => {
    dispatch(getCareerPageAction());
  }, []);
  const careeerData = useSelector(
    (e) => e?.DigipankSlice?.CareerPageState?.CareerPageStateData
  );
  console.log(careeerData);
  return (
    <div className="App">
      <Header handleClick={handleClick} />
      <div className="career_Page">
        <div className="banner-section">
          <div className="content-Top position-absolute">
            <Label title={careeerData?.title} className="top-Head"></Label>
            <Label title={careeerData?.subTitle} className="top-Inner"></Label>
          </div>
        </div>
        <div className="about-section">
          <div className="left-section">
            <div className="">
              <Label
                className="top"
                title={careeerData?.aboutUs?.heading}
              ></Label>
              <Label
                className="inner"
                title={careeerData?.aboutUs?.paraOne}
              ></Label>
              <Label
                className="inner"
                title={careeerData?.aboutUs?.paraTwo}
              ></Label>

              <div className="know_btn">
                <CustomButton title={careeerData?.aboutUs?.btnTitle}>
                  {" "}
                </CustomButton>
              </div>
            </div>
          </div>
          <div className="right-section">
            <img src={aboutImg} className="" alt="..." />
          </div>
        </div>
        <div className="why-section">
          <div className="center-section">
            <div className="right-section">
              <img src={aboutImg} className="" alt="..." />
            </div>
            <div className="left-section">
              <Label
                className="top"
                title={careeerData?.whyUs?.heading}
              ></Label>
              <Label
                className="inner"
                title={careeerData?.whyUs?.paragraph}
              ></Label>
              <div className="skills d-flex justify-content-between">
                <ul className="skills-left">
                  <li>
                    <img src={checkedImg} className="pl-1" />
                    {careeerData?.whyUs?.points[0]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[1]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[2]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[3]?.point}
                  </li>
                </ul>
                <ul className="skills-right">
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[4]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[5]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[6]?.point}
                  </li>
                  <li>
                    <img src={checkedImg} className="" alt="..." />
                    {careeerData?.whyUs?.points[7]?.point}
                  </li>
                </ul>
              </div>
              <div className="know_btn">
                <CustomButton title={careeerData?.whyUs?.btnTitle}>
                  {" "}
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
        <div className="career-position">
          <div className="top-header">
            <Label
              className="left"
              title={careeerData?.openPosition?.heading}
            ></Label>
            <div className="">
              <Label
                className="right"
                title={careeerData?.openPosition?.newPosition}
              ></Label>
            </div>
          </div>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {" "}
                <span>
                  {" "}
                  {careeerData?.openPosition?.positionsData[0]?.positionName}
                  <span className="position-absolute exp">
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.experienceRequired
                    }
                  </span>{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body>
                <Label
                  className="job-title paddTop"
                  title={
                    careeerData?.openPosition?.positionsData[0]?.jobDescription
                  }
                ></Label>
                <Label
                  className="descp"
                  title={
                    careeerData?.openPosition?.positionsData[0]?.description
                  }
                >
                  {" "}
                </Label>
                <Label
                  className="job-title"
                  title={
                    careeerData?.openPosition?.positionsData[0]
                      ?.keyResponsibilities
                  }
                ></Label>
                <ul>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                </ul>
                <Label
                  className="job-title paddTop"
                  title={
                    careeerData?.openPosition?.positionsData[0]
                      ?.skillsRequirement
                  }
                ></Label>
                <ul>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.requirements[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.requirements[1]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.requirements[2]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[0]
                        ?.requirements[3]?.point
                    }
                  </li>
                </ul>
                <div className="apply_btn d-flex justify-content-end">
                  <CustomButton title={careeerData?.openPosition?.positionsData[0]?.btnTitle}> </CustomButton>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                {" "}
                <span>
                {careeerData?.openPosition?.positionsData[1]?.positionName}
                  <span className="exp position-absolute">
                    {" "}
                    {careeerData?.openPosition?.positionsData[1]?.experienceRequired}
                  </span>{" "}
                </span>
              </Accordion.Header>
              <Accordion.Body>
              <Label
                  className="job-title paddTop"
                  title={
                    careeerData?.openPosition?.positionsData[1]?.jobDescription
                  }
                ></Label>
                <Label
                  className="descp"
                  title={
                    careeerData?.openPosition?.positionsData[1]?.description
                  }
                >
                  {" "}
                </Label>
                <Label
                  className="job-title"
                  title={
                    careeerData?.openPosition?.positionsData[1]
                      ?.keyResponsibilities
                  }
                ></Label>
                <ul>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.responsibilities[0]?.point
                    }
                  </li>
                </ul>
                <Label
                  className="job-title paddTop"
                  title={
                    careeerData?.openPosition?.positionsData[1]
                      ?.skillsRequirement
                  }
                ></Label>
                <ul>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.requirements[0]?.point
                    }
                  </li>
                  <li className="descp">
                    {" "}
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.requirements[1]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.requirements[2]?.point
                    }
                  </li>
                  <li className="descp">
                    {
                      careeerData?.openPosition?.positionsData[1]
                        ?.requirements[3]?.point
                    }
                  </li>
                </ul>
                <div className="apply_btn d-flex justify-content-end">
                  <CustomButton title={careeerData?.openPosition?.positionsData[1]?.btnTitle}> </CustomButton>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
        <div className="banner-section subscribe-section">
          <div className="content-Top position-absolute">
            <Label
              title={careeerData?.subscription?.heading}
              className="top-Head"
            ></Label>
            <Label
              title={careeerData?.subscription?.subHeading}
              className="top-Inner"
            ></Label>
            <div className="mail-section">
              <HookForm
                defaultForm={{}}
                init={(form) => setLoginForm(form)}
                onSubmit={onFormSubmit}
              >
                {(formMethod) => {
                  return (
                    <div className="mailInput d-flex">
                      <TextField
                        formMethod={formMethod}
                        rules={loginForm.email.validate}
                        name={loginForm.email.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder={careeerData?.subscription?.emailPlaceholder}
                      />

                      <div className="subs_Btn">
                        <CustomButton title={careeerData?.subscription?.subscribeBtn}> </CustomButton>
                      </div>
                    </div>
                  );
                }}
              </HookForm>
            </div>
          </div>
        </div>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default CareerPage;

import React, { useEffect, useState } from "react";
import Dropdownx from "../Dropdown/Dropdownx";
import HookForm from "../../components/HookForm/HookForm";
import TextField from "../../components/TextField/TextField";
import CustomButton from "../../components/CustomButton/CustomButton";
import Message from "../../util/message";
import Constant from "../../util/constant";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import Label from "../../components/Label/Label";
import ThankYouPopUp from "../../components/ThankYouPopUp/ThankYouPopUp";
import "./ContactUs.scss";
import { useDispatch, useSelector } from "react-redux";
import { getContactUsData } from "../../reduxThings/slices/DigipankSlices";

const contactForm = {
  name: {
    name: "name",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.FIRSTNAMEEMPTY,
      },
      pattern: {
        value: Constant.REGEX.Name,
      },
    },
  },
  lastname: {
    name: "lastname",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.LASTNAMEEMPTY,
      },
      pattern: {
        value: Constant.REGEX.Name,
      },
    },
  },
  email: {
    name: "email",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
  number: {
    name: "number",
    validate: {
      value: true,
      message: Message.ERRORMESSAGE.CONTACTEMPTY,
    },
    pattern: {
      value: Constant.REGEX.NUMBER,
      message: Message.ERRORMESSAGE.MININVALID,
    },
  },
};

function ContactUs() {
  const [value, setValue] = useState();
  const [form, setcontactForm] = React.useState();
  const [dimensions, setDimensions] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const dispatch = useDispatch();
  const [hidemodal, setHidemodal] = useState(false);

  React.useEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  useEffect(() => {
    dispatch(getContactUsData());
  }, []);
  const appData=useSelector((e)=>e?.DigipankSlice?.contactUsState?.contactUsStateData)
  // const navigateToThankYouPopUp = () => {
  //   navigate("/thankyoupopup")
  // }
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  const [carousalStatus, setCarousalStatus] = useState(true);
  const onFormSubmit = () => {};
  return appData?.contactUs?.title === 'Contact Us'?(
    <div
      className="contact_Maindiv"
      style={{ height: dimensions.height - 200 + "px" }}
      id="contact"
    >
      {/* <Header handleClick={handleClick} /> */}
      <div className="center_Section">
        <div className="content_Section">
          <Label title={appData?.contactUs?.title}></Label>
          <Label title={appData?.contactUs?.desc}></Label>
        </div>

        <div className="input_Center">
          <HookForm
            defaultForm={{}}
            init={(form) => setcontactForm(form)}
            onSubmit={onFormSubmit}
          >
            {(formMethod) => {
              return (
                <div className="form1">
                  <div className="first_Line">
                    <div className="whole_Name">
                      <TextField
                        formMethod={formMethod}
                        rules={contactForm.name.validate}
                        name={contactForm.name.name}
                        errors={formMethod?.errors}
                        autoFocus={true}
                        type="text"
                        placeholder={appData?.contactUs?.form?.namePlaceholder}
                      />
                      <span className="colorRed left100">*</span>
                    </div>
                    <div className="service">
                      <TextField
                        formMethod={formMethod}
                        rules={contactForm.lastname.validate}
                        name={contactForm.lastname.name}
                        errors={formMethod?.errors}
                        placeholder={appData?.contactUs?.form?.servicePlaceholder}
                        textFieldCss="textFieldCss"
                      />
                      <span className="colorRed left100">*</span>
                      <span>
                        {" "}
                        <Dropdownx />
                      </span>
                    </div>
                  </div>
                  <div className="second_Line">
                    <div className="email_Div">
                      <TextField
                        formMethod={formMethod}
                        rules={contactForm.email.validate}
                        name={contactForm.email.name}
                        errors={formMethod?.errors}
                        autoFocus={false}
                        type="text"
                        placeholder={appData?.contactUs?.form?.emailPlaceolder}
                      />
                      <span className="colorRed left100">*</span>
                    </div>
                    <div className="phone_Sec">
                      <PhoneInput
                        defaultCountry="US"
                        value={value}
                        onChange={setValue}
                        placeholder={appData?.contactUs?.form?.phonePlaceholder}
                      />
                    </div>
                  </div>
                  <div>
                    <TextField
                      formMethod={formMethod}
                      rules={contactForm.name.validate}
                      name={contactForm.name.name}
                      errors={formMethod?.errors}
                      autoFocus={false}
                      type="text"
                      placeholder={appData?.contactUs?.form?.descriptionPlaceholder}
                    />
                  </div>
                  <div className="submit_Btn">
                    <CustomButton
                      title={appData?.contactUs?.form?.buttonTitle}
                      // onClick={navigateToThankYouPopUp}
                      onClick={() => setHidemodal(!hidemodal)}
                    >
                      {" "}
                    </CustomButton>
                    <i className="icon-arrow"></i>
                  </div>
                </div>
              );
            }}
          </HookForm>
        </div>
      </div>
      <ThankYouPopUp
        showModal={hidemodal}
        onHide={() => setHidemodal(false)}
        onHideClick={() => setHidemodal(false)}
        onLogoutClick={() => setHidemodal(false)}
      />
      {/* <Footer></Footer> */}
    </div>
  )
  :<div>..loading</div>
}

export default ContactUs;

import React, { useState, useContext } from 'react';
import CustomButton from '../../components/CustomButton/CustomButton';
import { Fade, Modal } from 'react-bootstrap';
import Label from '../Label/Label';
import BgImg from "../../assests/img/cancel.png";
import Close from "../../assests/img/close.png";
import './ThankYouPopUp.scss';

const ThankYouPopUp = (props) => {

  return (
    <Modal
      className={'ThankYouPopUp ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <Modal.Header>
        <div className='header-img'>
          <img src={BgImg} className="bgImg"></img>
          <img src={Close} className="close"></img>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Label className='headerTitle' title='Thank You!'></Label>
        <Label className='name' title='John Peter'></Label>
        <Label className='inner' title='Our executive will connect with you shortly.'></Label>
      </Modal.Body>
      <div className='modal-footer'>
        <CustomButton type="submit" title='CONTINUE' />
      </div>
    </Modal>
  )

}
ThankYouPopUp.defaultProps = {
  className: 'ThankYouPopUp',
  btnHide: true
}

export default (ThankYouPopUp);
import Certificate from "../../assests/img/certificate.png";
import "./VerifyCertificate.scss";
import Info from "./Info";
import React, { useState, useEffect } from "react";
import Group from "../../assests/img/Group.png";
import Header from "../../components/Header/Header";
import Label from "../../components/Label/Label";
import TextField from "../../components/TextField/TextField";
import HookForm from "../../components/HookForm/HookForm";
import Calendar from "../../components/Calendar/Calendar";
import CustomButton from "../../components/CustomButton/CustomButton";
import Message from "../../util/message";
import Constant from "../../util/constant";
import Footer from "../../components/Footer/Footer";
import { getVerifyCertificateAction } from "../../reduxThings/slices/DigipankSlices";
import { useDispatch, useSelector } from "react-redux";

const CertificateForm = {
  certificate: {
    name: "certificate",
    validate: {
      required: {
        value: true,
        message: Message.ERRORMESSAGE.EMAILEMPTY,
      },
      pattern: {
        value: Constant.REGEX.email,
        message: Message.ERRORMESSAGE.EMAILINVALID,
      },
    },
  },
};
const VerifyCertificate = () => {
  const [form, setCertificateForm] = useState();
  const [certificate, setCertificate] = useState({
    firstName: "FirstName ",
    lastName: "lastName",
  });
 
  const dispatch = useDispatch();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return (_) => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const appData = useSelector(
    (e) => e?.DigipankSlice?.verifyCertificateState?.verifyCertificateStateData
  );

  useEffect(() => {
    dispatch(getVerifyCertificateAction());
  }, []);

  const onFormSubmit = () => {
    console.log("clicked verify form")
    setCertificate({
      firstName: "Anthony ",
      lastName: "Jonson",
    });
  };
  const handleDownload=()=>{
    const imageUrl=Certificate;
    const link =document.createElement('a');
    link.href= imageUrl;
    link.download='certificate.png';
    link.click();

  }
  return appData?.title === "Verify certificate" ? (
    <div className="downloadCertificate">
      <div className="verifyContainer">
        <div className="verifyContainer_inner">
          <Header handleClick={handleClick} />
          <div
            className="centerDiv"
            style={{ height: dimensions.height + "px" }}
          >
            <div className="certificateContainer">
              <Label className="verifyTitle" title={appData?.title} />
              <Label className="verifyPara" title={appData?.subTitle} />
              <div className="form">
                <HookForm
                  defaultForm={{}}
                  init={(form) => setCertificateForm(form)}
                  onSubmit={onFormSubmit}
                >
                  {(formMethod) => {
                    return (
                      <div className="form1">
                        <TextField
                          formMethod={formMethod}
                          rules={CertificateForm.certificate.validate}
                          name={CertificateForm.certificate.name}
                          errors={formMethod?.errors}
                          autoFocus={true}
                          type="text"
                          placeholder={appData.certificateNumberPlaceholder}
                        />
                        <Calendar
                          birthdatePlaceHolder={appData.birthdatePlaceHolder}
                        />
                        <div className="verify_Btn">
                          <CustomButton
                          onClick={onFormSubmit}
                            title={appData.verifyBtn}
                          ></CustomButton>
                        </div>
                      </div>
                    );
                  }}
                </HookForm>
              </div>
            </div>
          </div>
        </div>
        <div className="certificateBox">
          <div className="congrats">
            <div className="congratsText">{appData?.congrats}</div>
            <img src={Group} alt="..." width="100%" />
          </div>
          <Info
            name={` ${certificate.firstName}  ${certificate.lastName}`}
            program={appData.program}
            icon={Certificate}
          />
          <div className="downloadButton">
            <CustomButton title={appData?.downloadBtn} onClick={handleDownload}></CustomButton>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  ) : (
    <div>...loading</div>
  );
};
export default VerifyCertificate;

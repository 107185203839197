import Login from "./pages/Login/Login";
import HomePage from "./pages/HomePage/homepage";
import DetailsPage from "./pages/DetailsPage/DetailsPage";
import LeavePage from "./pages/LeavePage/LeavePage";
import ServicesPage from "./pages/Ourservices/Ourservices";
import AppMap from "./AppMap";
import Privacy from "./pages/PrivacyPolicy/Privacy";
import TermCondition from "./pages/Term&Condition/TermCondition";
import ContactPage from "./pages/ContactPage/ContactPage";
import Portfolio from "./pages/PortfolioPage/Portfolio";
import IosDevelopment from "./pages/IosDevelopment/iosdevelopment";
import PackagesSEO from "./pages/PackagesSEO/PackagesSEO";
import AllCertificates from "./pages/AllCertificates/AllCertificates";
import CaseStudy from "./pages/CaseStudy/CaseStudy";
import BlogPage from "./pages/BlogPage/BlogPage";
import BlogListing from "./pages/BlogListing/BlogListing";
import PortfolioListing from "./pages/PortfolioListing/PortfolioListing";
import AboutUs from "./pages/AboutUs/AboutUs";
import InnerPage from "./pages/InnerPage/InnerPage";
import ApplyLeave from "./pages/ApplyLeave/ApplyLeave";
import CareerPage from "./pages/CareerPage/CareerPage";
import TestingPage from "./pages/TestingPage/TestingPage";
import VerifyCertificate from "./pages/VerifyCertificate/VerifyCertificate";
const routes = [
  {
    path: "/",
    component: <HomePage />,
    exact: true,
  },
  {
    path: "/appmap",
    component: <AppMap />,
    exact: true,
  },
  {
    path: "/privacy",
    component: <Privacy />,
  },
  {
    path: "/termcondition",
    component: <TermCondition />,
    exact: true,
  },
  {
    path: "/login",
    component: <Login />,
    exact: true,
  },
  {
    path: "/career",
    component: <CareerPage/>,
    exact: true,
  },
  {
    path: "/homepage",
    component: <HomePage />,
    exact: true,
  },
  {
    path: "/contactpage",
    component: <ContactPage />,
    exact: true,
  },
  {
    path: "/detailspage",
    component: <DetailsPage />,
    exact: true,
  },
  {
    path: "/leave",
    component: <LeavePage />,
    exact: true,
  },
  {
    path: "/ourservices",
    component: <ServicesPage />,
    exact: true,
  },
  {
    path: "/packagesseo",
    component: <PackagesSEO />,
    exact: true,
  },
  {
    path: "/iosdevelopment",
    component: <IosDevelopment />,
    exact: true,
  },
  {
    path: "/portfolio",
    component: <Portfolio />,
    exact: true,
  },
  {
    path: "/allcertificates",
    component: <AllCertificates />,
    exact: true,
  },
  {
    path: "/verifycertificate",
    component: <VerifyCertificate />,
    exact: true,
  },
  {
    path: "/casestudy",
    component: <CaseStudy />,
    exact: true,
  },
  {
    path: "/blogpage",
    component: <BlogPage />,
    exact: true,
  },
  {
    path: "/bloglisting",
    component: <BlogListing />,
    exact: true,
  },
  {
    path: "/portfoliolisting",
    component: <PortfolioListing />,
    exact: true,
  },
  {
    path: "/aboutus",
    component: <AboutUs />,
    exact: true,
  },
  {
    path: "/innerpage",
    component: <InnerPage/>,
    exact: true,
  },
  {
    path: "/aboutus",
    component: <AboutUs/>,
    exact: true,
  },
  {
    path: "/applyleave",
    component: <ApplyLeave/>,
    exact: true,
  },
  {
    path: "/testing",
    component: <TestingPage/>,
    exact: true,
  },
];

export default routes;

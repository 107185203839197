import React, { useState } from "react";
import "./Calendar.scss";
import DatePicker from "react-datepicker";

const Calendar = (props) => {
  const [startDate, setStartDate] = useState(null);
  return (
    <div className="date">
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        placeholderText={props.birthdatePlaceHolder}
      />
      <i className="icon-mail iconCalendar"></i>
    </div>
  );
};

export default Calendar;

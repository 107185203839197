import React from 'react';
import CustomButton from '../CustomButton/CustomButton';
import { Fade, Modal } from 'react-bootstrap';
import Label from '../Label/Label';
import './ModalPopup.scss';

const ModalPopup = (props) => {
  let {buttonTitle, headerTitle} = props;
  return (
    <Modal
      className={'ModalPopup ' + props.className}
      show={props.showModal}
      animation={Fade}
      onHide={() => props.onHide()}
      centered
    >
      <Modal.Header>
       <Label title={headerTitle}></Label>
      </Modal.Header>
      <Modal.Body>
      {props?.children}
      </Modal.Body>
      <div className='modal-footer'>
        <CustomButton type="submit" title={buttonTitle} />
      </div>
    </Modal>
  )

}
ModalPopup.defaultProps = {
  className: 'modalPopup',
  btnHide: true
}

export default (ModalPopup);
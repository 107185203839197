import {useState} from 'react'
import ContactUs from '../../components/ContactUs/ContactUs'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'

const ContactPage = () => {
    const [carousalStatus, setCarousalStatus] = useState(true);

    const handleClick = (data) => {
      setCarousalStatus(data);
    };
  return (
    <div>
        <Header handleClick={handleClick}/>
        <ContactUs/>
        <Footer/>
    </div>
  )
}

export default ContactPage
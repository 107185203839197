import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import {
  NavDropdown,
  Nav,
  Navbar,
  Accordion,
  Container,
  Dropdown,
} from "react-bootstrap";
import "./Header.scss";
import LogoImg from "../../assests/img/logo.png";
import BottomImg from "../../assests/img/menu-bg.png";
import CustomButton from "../CustomButton/CustomButton";
import Label from "../Label/Label";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import socialImg from "../../assests/img/social.svg";

const Header = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();

  let navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
  };


  const clickToNavigate = (page) => {
    if (page === "bloglisting") {
      navigate("/bloglisting");
    } else if (page === "portfolio") {
      navigate("/portfolio");
    } else if (page === "contactUs") {
      navigate("/contactpage");
    } else if (page === "iosDevelopment") {
      navigate("/iosdevelopment");
    } else if (page === "homepage") {
      navigate("/");
    }
  };
  const ModalPort = () => {
    return (
      <>
        <Modal show={show} onHide={handleClose} className="modal">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="modalBody">
            <div className="portfolio_Page">
              <div className="content_Section">
                <Label title="Portfolio"></Label>
                <div></div>
                <Label
                  title="Sed ut perspiciatis unde omnis 
              iste natus error sit voluptatem accusantium doloremque
               laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo."
                ></Label>
              </div>

              <Carousel className="image_Section">
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="First slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="Second slide"
                  />
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block w-100 img-fluid"
                    src={socialImg}
                    alt="Third slide"
                  />
                </Carousel.Item>
              </Carousel>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  };
  console.log("pathname", location.pathname);
  return (
    <Navbar bg="light" expand="lg" className="ml-auto menuListing">
      <ModalPort />
      <Container className="container_Section">
        <Navbar.Brand>
          <div
            class="brand"
            className="logo-Img"
            onClick={() => clickToNavigate("homepage")}
          >
            <img src={LogoImg} alt="..." />
          </div>
        </Navbar.Brand>
        <div className="main-Container">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <NavDropdown
              title="Service"
              // id="basic-nav-dropdown"
              onClick={() => clickToNavigate("ourService")}
              className="hoverToSee"
            >
              <div className="show-modal">
                <div className="dropdown-Options iPad">
                  <div className="item-List">
                    <NavDropdown.Item href="#" className="mainTitle">
                      <i className="icon-mobile colorRed"></i>Mobile Apps
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="borderB colorRed"
                      onClick={() => clickToNavigate("iosDevelopment")}
                    >
                      Native iOS Apps
                    </NavDropdown.Item>
                    <NavDropdown.Item>Native Android Apps</NavDropdown.Item>
                    <NavDropdown.Item>Cross Platform</NavDropdown.Item>
                    <NavDropdown.Item>Hybrid Apps</NavDropdown.Item>
                    <NavDropdown.Item>PWA</NavDropdown.Item>
                  </div>
                  <div className="item-List">
                    <NavDropdown.Item href="#" className="mainTitle">
                      <i className="icon-desktop colorRed"></i>Web Development
                    </NavDropdown.Item>
                    <NavDropdown.Item>Enterprise Solutions</NavDropdown.Item>
                    <NavDropdown.Item>ECommerce</NavDropdown.Item>
                    <NavDropdown.Item>CMS</NavDropdown.Item>
                    <NavDropdown.Item>API & Backend</NavDropdown.Item>
                    <NavDropdown.Item>Custom Development</NavDropdown.Item>
                  </div>
                  <div className="item-List">
                    <NavDropdown.Item href="#" className="mainTitle">
                      <i className="icon-design colorRed"></i>Design{" "}
                    </NavDropdown.Item>
                    <NavDropdown.Item>Discovery Workshop</NavDropdown.Item>
                    <NavDropdown.Item>Analysis</NavDropdown.Item>
                    <NavDropdown.Item>Wireframe</NavDropdown.Item>
                    <NavDropdown.Item>UI / UX Design</NavDropdown.Item>
                  </div>
                  <div className="item-List paddR">
                    <NavDropdown.Item href="#" className="mainTitle paddR">
                      <i className="icon-marketing colorRed"></i>Marketing{" "}
                    </NavDropdown.Item>
                    <NavDropdown.Item>Content</NavDropdown.Item>
                    <NavDropdown.Item>PPC Campaigns</NavDropdown.Item>
                    <NavDropdown.Item>Social Media</NavDropdown.Item>
                  </div>
                </div>
                <div className="mobile_Resp">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>
                        <i className="icon-mobile colorRed"></i>Mobile Apps
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="dropdown-Items">
                          <li>
                            <NavDropdown.Item className="borderB colorRed">
                              Native iOS Apps
                            </NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>
                              Native Android Apps
                            </NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>Cross Platform</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>Hybrid Apps</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>PWA</NavDropdown.Item>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <i className="icon-desktop colorRed"></i>Web Development
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="dropdown-Items">
                          <li>
                            <NavDropdown.Item>
                              Enterprise Solutions
                            </NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>ECommerce</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>CMS</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>API & Backend</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>
                              Custom Development
                            </NavDropdown.Item>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>
                        <i className="icon-design colorRed"></i>Design{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="dropdown-Items">
                          <li>
                            <NavDropdown.Item>
                              Discovery Workshop
                            </NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>Analysis</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>Wireframe</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>UI / UX Design</NavDropdown.Item>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                      <Accordion.Header>
                        <i className="icon-marketing colorRed"></i>Marketing{" "}
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul className="dropdown-Items p0">
                          <li>
                            <NavDropdown.Item>Content</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>PPC Campaigns</NavDropdown.Item>
                          </li>
                          <li>
                            <NavDropdown.Item>Social Media</NavDropdown.Item>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
                <div className="btm_Img">
                  <div className="image">
                    <img src={BottomImg} alt="..." />
                    <div className="text-Btn">
                      <div className="title-Btn">
                        <Label title="Discovery Workshop"></Label>
                        <Label title="Define functional scope of your project to plan project budget and timeline."></Label>
                      </div>
                      <CustomButton
                        onClick={() => clickToNavigate("contactUs")}
                        type="button"
                        title="Contact us"
                      ></CustomButton>
                    </div>
                  </div>
                </div>
              </div>
            </NavDropdown>
            <Nav className="me-auto">
              <Nav.Link onClick={() => clickToNavigate("portfolio")}>
                Portfolio
              </Nav.Link>

              <Nav.Link onClick={() => clickToNavigate("bloglisting")}>
                Blog
              </Nav.Link>
              {location.pathname === "/homepage" ||
              location.pathname === "/ourservices" ||
              location.pathname === "/aboutus" ||
              location.pathname === "/iosdevelopment" ? (
                <Nav.Link href="#contact">Contact</Nav.Link>
              ) : (
                <Nav.Link onClick={() => clickToNavigate("contactUs")}>
                  Contact
                </Nav.Link>
              )}

              <Nav.Link
                onClick={() => clickToNavigate("contactUs")}
                className="colorRed"
              >
                Free website audit
              </Nav.Link>
              {/* <Nav.Link>
                <span className="profile">
                  <i className="icon-profile profileCss"></i>
                </span>
              </Nav.Link> */}
              <Dropdown className="profile-dropdown">
                <Dropdown.Toggle variant="" id="dropdown-basic">
                  <span className="profile">
                    <i className="icon-profile profileCss"></i>
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-profile profileCss dropdown-profile"></i>{" "}
                      John Peter
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-profile profileCss dropdown-profile"></i>{" "}
                      johnpeter@11
                    </span>
                  </Dropdown.Item>
                  <Dropdown.Item>
                    <span className="profile d-flex align-items-center">
                      <i className="icon-profile profileCss dropdown-profile"></i>{" "}
                      Logout
                    </span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </div>
      </Container>
    </Navbar>
  );
};

export default Header;

import React, { useState, useEffect } from "react";
import Header from "../../components/Header/Header";
import Label from "../../components/Label/Label";
import Footer from "../../components/Footer/Footer";
import socialImg from "../../assests/img/social.svg";
import previous from "../../assests/img/previous.svg";
import next from "../../assests/img/next.svg";
import "./Portfolio.scss";
import { useDispatch, useSelector } from "react-redux";
import { getPortfolioAction} from "../../reduxThings/slices/DigipankSlices";
function Portfolio() {
  const [carousalStatus, setCarousalStatus] = useState(true);
  const dispatch = useDispatch();
  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    dispatch(getPortfolioAction());
  }, []);
  const appData = useSelector(
    (e) => e?.DigipankSlice?.PortfolioState?.PortfolioStateData
  );
  console.log("data portfolio",appData)
  return appData?.title === "Portfolio" ? (
    <div className="portfolio_Page">
      <Header handleClick={handleClick} />
      <div className="content_Section">
        <Label title={appData?.title}></Label>
        <Label title={appData?.desc}></Label>
      </div>
      <div className="image_Section">
        <img src={socialImg} className="social_Image" alt="..." />
      </div>
      <div className="icon_Section">
        <img className="prev" src={previous} alt="..." />
        <img src={next} alt="..." />
      </div>
      <Footer></Footer>
    </div>
  ) : (
    React.createElement("h1", {}, "...loading")
  );
}

export default Portfolio;

import {useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./InnerPage.scss";
import Header from "../../components/Header/Header";
import ListingImg from "../../assests/img/listing.png";
import ListingImg1 from "../../assests/img/listing1.png";
import Label from "../../components/Label/Label";
import Footer from "../../components/Footer/Footer";


function InnerPage() {
  const [carousalStatus, setCarousalStatus] = useState(true);

  const handleClick = (data) => {
    setCarousalStatus(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);
  const ListingData = [
    {
      main: 'WebApp',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Ios',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg1,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Android',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'WebApp',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg1,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Ios',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Android',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg1,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'WebApp',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Ios',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg1,
      learnMore: 'LEARN MORE'
    },
    {
      main: 'Android',
      header: 'How Ios is a Blessing for the Businesses of Today?',
      inner_Text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type....",
      img: ListingImg,
      learnMore: 'LEARN MORE'
    },
  ]
  return (
    <div className="inner_Page">
      <Header handleClick={handleClick}/>
      <div className="inner_Main">
        <Tabs
          defaultActiveKey="all"
          id="uncontrolled-tab-example"
          className="tab_Main_Section"
        >
          <Tab eventKey="all" title="All">
            <div className="conatiner">
              {ListingData.map((item) => (
                <div className="tab_Container">
                  <div className="tab_Content">
                    <div className="main_tab_Section">
                      <img src={item.img} alt="image" fluid="true" />
                      <div className="content_Section">
                        <div className="height"></div>
                        <Label className='main_Label' title={item.main}>
                        </Label>
                        <Label className='header_Label' title={item.header}>
                        </Label>
                        <div className="text_More">
                          <p className='inner_Txt' >
                         {item.inner_Text}
                          <a className='learnMore'> {item.learnMore}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Tab>
          <Tab eventKey="ios" title="Ios">
            <div>2</div>
          </Tab>
          <Tab eventKey="android" title="Android">
            <div>33</div>
          </Tab>
          <Tab eventKey="webapp" title="WebApp">
            <div>66</div>
          </Tab>
        </Tabs>
        <Footer></Footer>
      </div>
    </div>
  );
}


export default InnerPage;
import React, { useState } from 'react';
import { NavDropdown, Nav, Dropdown, Navbar, Accordion , Container} from 'react-bootstrap';
import './HeaderLogin.scss';
import LogoImg from '../../assests/img/logo.png'
import { useNavigate } from "react-router-dom";
import BottomImg from '../../assests/img/menu-bg.png'
// import Button from '@restart/ui/esm/Button';
import CustomButton from '../CustomButton/CustomButton';
import Label from '../Label/Label';

const HeaderLogin = (props) => {
   let navigate = useNavigate();
   const clickToNavigate = (page) => {
      if (page === "homepage") {
        navigate("/homepage");
      }
    };
  return (

<Navbar bg="light" expand="lg" className="ml-auto loginHeader" alignRight>
   <Container>
      <Navbar.Brand href="#home">
         <a class="brand" href="#" className='logo-Img'>
         <img src={LogoImg} onClick={()=>clickToNavigate("homepage")} alt=".."/>
         </a>
      </Navbar.Brand>
      <div className='main-Container'>
         <Navbar.Toggle aria-controls="basic-navbar-nav" />
         <Navbar.Collapse id="basic-navbar-nav" class='show displayShow'>
         <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
             <i className='icon-mail iconCss'></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
               <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
               <Dropdown.Item href="#/action-2">Change Password</Dropdown.Item>
               <Dropdown.Item href="#/action-3">Setting</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
         </Navbar.Collapse>
      </div>
   </Container>
</Navbar>




);
}

export default HeaderLogin